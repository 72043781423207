import {Link} from "react-router-dom"
import "./navigation.css";

const Navigation =()=>{

    return(
        <ul className="navigation">
            <li className="navItem"><Link to="/"> HOME </Link></li>
            <li className="navItem"><Link to="/contacts"> CONTACTO </Link></li>
            <li className="navItem"><Link to="/company"> EMPRESA </Link></li>
         
        </ul>
    )
}
export default Navigation;