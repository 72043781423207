import { Link } from "react-router-dom";
import "./services.css";

const Services=()=>{
    return(
        <div className="services screen">
            <ul className="services-list">
                <li><h3>SERVICES</h3></li>
                <li><a href="/#InstalacionesElectricas">> Instalaciones Electricas</a></li>
                <li><a href="/#Iluminación">> Iluminación</a></li>
                <li><a href ="/#Mantenimiento">> Mantenimiento Eléctrico</a></li>
                <li><a href ="/#Camaras">> Instalación Cámaras Seguridad</a></li>
                <li><Link>> Consultoría y Asesoramiento </Link></li>
            </ul>
        </div>
    )
}

export default Services;