import "./company.css";

const Company=({theme})=>{
    return(
        <div className={`pages company ${theme}`}>
            <div className="company-sections"> 
                <h1 className="company-title">
                    Nuestra Experiencia y Compromiso:
                    <br/>
                    <sup className="subtitle">
                        Conoce Tu Chispa, Expertos En Servicios Eléctricos
                    </sup>
                </h1>
                <text className="company-description">
                    Tu Chispa es una empresa de servicios eléctricos comprometida con brindar soluciones confiables y eficientes a nuestros clientes. Con un equipo de expertos en electricidad, ofrecemos servicios desde la instalación y reparación de sistemas eléctricos hasta la consultoría y asesoramiento en proyectos eléctricos. Nos enorgullece satisfacer las necesidades de nuestros clientes, manteniendo altos estándares de calidad y seguridad en cada trabajo que realizamos. Contacte con nosotros para obtener más información sobre cómo podemos ayudar con sus necesidades eléctricas.
                </text>
            </div>
        </div>
    )
}

export default Company;