import "./notFound.css";
import { Link } from "react-router-dom";
import notFoundImage from "./notFound.jpg"

const NotFound =()=>{
    return(
        <div className="pages notFound">
            <div className="centered">
                <img src={notFoundImage}  className="image404 image404mobile" alt="notfound"/>
                    <Link to="/" className="linkTo">
                        Home Page
                    </Link>
            </div>

        </div>
    )
}
export default NotFound;