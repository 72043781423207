import React, { useState }from 'react';
import emailjs from 'emailjs-com';
import "./contactUS.css";

const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID
const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID
const userID = process.env.REACT_APP_EMAILJS_USER_ID

const ContactUs =({screen_mobile, theme})=>{
    const [messageState, setMessageState]=useState(false)

    let formtheme=`form-${theme}`

    const sendEmail=(e)=> {
        e.preventDefault();
    
        emailjs.sendForm(
                serviceID,
                templateID,
                e.target,
                userID
            )
          .then((result) => {
                console.log(result.text);
                setMessageState(true)
              
          }, (error) => {
              console.log(error.text);
              alert(error.text)
          });
    }
    if(messageState){
        return(
            <div className={`user-dialog ${formtheme}`}>
                <h2>
                    ¡Gracias por su mensaje! Le contactaremos en breve
                </h2>
                <button className='back-btn' onClick={()=>setMessageState(false)}> Volver a escribir</button>
            </div>
        )
    }
    return(
        <form onSubmit={sendEmail} className={`contact-form contact-page-form ${formtheme} ${screen_mobile}`}>
            <input className="form-inputs name" name="user-name" type="text" placeholder=" nombre *" required/>
            <input className="form-inputs email" name="user-email" type="email" placeholder=" correo electrónico *"required/>
            <input className='form-inputs phone' name="user-phone" type="phone" placeholder=" número de teléfono *" required/>
            <textarea
                className=" form-inputs message"
                name="message"
                id=""
                cols="60"
                rows="5"
                placeholder=" mensaje *"
                required
            ></textarea>
            <button className="submit" type="submit">Te Contactamos</button>
        </form>
    )
}

export default ContactUs;