import on from "./switch_on.png"
import off from "./switch_of.png"

import "./switch-theme.css"
const SwitchTheme =({changeTheTheme,theme})=>{
    let switchBtn;
    if(theme === "day" || theme === [null]){
        switchBtn=on
    }else if(theme === "night"){
        switchBtn=off 
    }else{
        switchBtn=on
    }
    return(
        <div className={`switch-frame ${theme}-frame`} >
            <div className="button-background"> </div> 

        <div className={`switch-box click-on-${theme}`} style={{backgroundImage: `url(${switchBtn})`}}>
            <button className="switch" onClick={()=>changeTheTheme("on")}></button>
            <button className="switch" onClick={()=>changeTheTheme("of")}></button>
        </div>
    </div>
    )
}

export default SwitchTheme;