import { Component } from 'react';
import {Route,Routes } from "react-router-dom";

import './App.css';
import { Header, Footer }from "./components";
import{Home,Contacts,Company,NotFound} from "./pages"

export default class App extends Component{
  state={
    theme:null
  }
  changeTheTheme=(buttonState)=>{
    if(buttonState === "on"){
      this.setState(state=>{
        return({
          theme:"day"
        })
      })
    }else{
      this.setState(state=>{
        return({
          theme:"night"
        })
      })
    }
  }


  render(){
    let {theme}=this.state;
    if(theme===null){
      theme=[]
    }
    return (
      <div className={`App ${ theme}`}>
        <Header changeTheTheme={this.changeTheTheme} theme={theme}/>
        <Routes>

          <Route path="/" element={<Home theme={theme}/>}/>
          <Route path="/contacts" element={<Contacts theme={theme}/>}/>
          <Route path="/company" element={<Company theme={theme}/>}/>

          <Route path='/*' element={<NotFound/>}/>
        </Routes>
        <Footer/>
      </div>
    );
  }

}

