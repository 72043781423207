import {
    HomeContent
    // Projects
} from "./homeComponents"
import "./home.css";
const Home = ({theme})=>{

    return(
        <div className={`pages home ${theme}`}>
            <h1 className="Home_Title">SERVICIOS ELÉCTRICOS RESIDENCIALES Y COMERCIALES</h1>
            <HomeContent theme={theme} />
            {/* <Projects/> */}
        </div>
    )
}

export default Home;