
import React, { useState } from 'react';
import { Map, GoogleApiWrapper
        //  , InfoWindow, Marker 
        } from 'google-maps-react';

import "./map.css";
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY


const MyMap = (props) => {
    const [
        // map,
        setMap
    ] = useState(null);
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    // const [selectedPlace, setSelectedPlace] = useState({});
    const [
        // activeMarker, 
        setActiveMarker
    ] = useState({});

    const onMapClicked = (props) => {
        if (showingInfoWindow) {
            setShowingInfoWindow(false);
            setActiveMarker(null);
        }
    }


    return (
        <div className="mapContainer">
            <h2>Área de actividades</h2> 

            <Map
                google={props.google}
                zoom={11}
                initialCenter={{ lat: 39.4679948, lng:  -0.4888058 }}
                onReady={(mapProps, map) => setMap(map)}
                onClick={onMapClicked}
            >
                {/* <Marker onClick={onMarkerClick} name={'Current location'} /> */}
                {/* <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
                    <div>
                        <h1>{selectedPlace.name}</h1>
                    </div>
                </InfoWindow> */}
            </Map>
        </div>
    );
}

<script async
  src={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=localContext&v=beta&callback=initMap`}>
</script>


export default GoogleApiWrapper({
    apiKey: API_KEY
})(MyMap);