import "./social.css";

const Social=()=>{
return (
    <div className="footer-social">

        <h3> Encuéntranos </h3>
        <div className="social-container">
            <a className="social-buttons facebook"
                href="https://www.facebook.com/tuchispa.es" 
                target={"-blank"}
            >
            
            </a>

            <a className="social-buttons whatsapp"  
                href="https://wa.me/+34631301804" 
            >
            </a>
            
            <button className="social-buttons instagram">
            
            </button>
            <button className="social-buttons telegram"> 
            
            </button>
            
        </div>
        <h3 className="social-lines mobile ">info.tuchispa@gmail.com</h3>
    </div>
)
}
export default Social;