import { Link } from "react-router-dom";
import "./header.css";

import {
    Navigation,
    SwitchTheme
} from "./headerComponents"; 

const Header = ({theme,changeTheTheme})=>{
    return(
        <div className="header">
            <div className="top">
                <Link to="/" className={`LogoLink LogoLink${theme}`}>
                    <h1 
                        className={`title `} 
                        style={{background:"none"}}
                    >
                        <span
                            className="tomato"
                        >
                            Tu
                        </span>
                        chispa.es
                    </h1>
                </Link>
                <h2 className="contact-phone">+34 631 301 804</h2>
            </div>
            <Navigation/>
            <div className="call-us-fade mobile" >
                <a  className="call-us-buttn" href="tel:+34-631-301-804"
                    style={{ 
                        backgroundImage: `url(${process.env.PUBLIC_URL + 'storage/icons/callus.png'})` 
                    }}>
                </a>
            </div>
            <SwitchTheme changeTheTheme={changeTheTheme} theme={theme}/>

        </div>
        
    )
}

export default Header;