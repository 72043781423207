import "./contacts.css";
import ContactUs from "../../components/footer/contactUs"
import {MyMap} from "../../components";

import callCenter from "./media/call-center.png";

const Contacts=({theme})=>{

    return(
        <div className={`pages contacts ${theme}`}>
            <h2 className="contacts-title">ESTAMOS AQUÍ POR TÍ</h2>
            <div className={`contacts-container ${theme}`}>
                <div className="contacts-section">
                    {/* <h2 className="contacts-title">ESTAMOS AQUÍ POR TÍ</h2> */}
                    <p className="contacts-description">
                        En Tu Chispa somos técnicos electricistas cualificados, lo que significa que somos profesionales, totalmente formados con los conocimientos e experiencia que buscas. Así que si necesitas reparación, instalación o mantenimiento eléctrico, estamos aquí para ayudarte. Contáctenos hoy por teléfono, WhatsApp, correo electrónico o nuestro formulario en línea, y nos comunicaremos contigo lo antes posible. ¡Esperamos poder servirte!
                    </p>
                    <br/>
                    <br/>
                    <a  className="mobile-centered phone-number"
                        href="tel:+34-631-301-804"
                        style={{lineHeight:1}}
                        >

                        +34 631 301 804
                    </a>
                    <div className="button-fade">
                        <a className="text-whatsapp-button"  
                        href="https://wa.me/+34631301804" 
                        >
                            Ir a Whatsapp
                        </a>
                    </div>
                    <br/>
                    <br/>

                    <div className="second-column mobile">
                        <img className="contacts-image-size1" src={callCenter} alt="callcenter"/>

                        <ContactUs theme={theme}/>
                    </div>

                    <span className="screen mail-address mobile-centered" style={{lineHeight:1}}> info.tuchispa@gmail.com </span>
                    <br className="screen"/>
                    <br/>

                    <h3>Nuestro horario de trabajo:</h3>
                    <span>Lunes-Viernes: 09:00 - 18:00</span>
                    <br/>
                    <span>Sabado: 09:00 - 14:00</span>
                    <br/>
                    <span> Domingo: <span style={{color: "tomato"}}>Descansamos</span> </span>

                    <br/>
                    <br/>
                    <MyMap/>
                </div>
                <div className="second-column screen">
                    <img className="contacts-image-size1" src={callCenter} alt="callcenter"/>

                    <ContactUs theme={theme}/>
                
                </div>
            </div>
        </div>
    )
}
export default Contacts;