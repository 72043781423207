import React, { Component } from "react";
import Services from "./services"
import Social from "./social";
import ContactUs from "./contactUs";
import "./footer.css";
export default class Footer extends Component{
    state={
        
    };
    
    render(){
        return(
            <div className="footer" >
                <div className="flex-box">
                    <Services/>
                     <Social/>
                    <ContactUs screen_mobile={"screen"}/>
                </div>

               <span className="copyright" > © 2021 - 2023 MKHITAR KHURSHUDYAN </span>
            </div>
        )
    }
}