import React, {useState} from 'react'
import "./homeContent.css";
import ilumination from "../../media/home_ilumination.png";
import installation from "../../media/installation.png";
import electrician from "../../media/electrician_worker.png";
import security_camera from "../../media/cctv_camera.jpg";

const HomeContent = ({theme}) => {
    return(
        <div className={` homeContent ${theme}`}>
                

            <div id="InstalacionesElectricas" className="homeSection">
                <h2 className="home-section-title mobile"> 
                    Instalaciones Eléctricas
                </h2>
                <img className="home-image-size1" src={installation} alt="homeimage1"/>
 
                <div className="home-section-info">
                    <h2 className="home-section-title screen"> 
                        Instalaciones Eléctricas
                    </h2>
                    <Description 
                    text={
                        `
                        Realizamos Instalaciones electricas en viviendas, oficinas, locales comerciales e industriales. Con Tu Chispa podeís estar seguros en su instalación eléctrica, porque los trabajos se realizan por profesionales autorizados y con cableado y componentes de alta calidad, para garantizar el funcionamiento a largo plazo y la máxima seguridad  de sus instalaciones
                        `
                    }
                    />
                </div>
            </div>

            <div id="Iluminación" className="homeSection">
                <div className="home-section-info">
                    <h2 className="home-section-title mobile"> 
                        Iluminación
                    </h2>
                    <img className="home-image-size1 mobile" src={ilumination} alt="homeimage1"/>

                    <h2 className="home-section-title screen"> 
                        Iluminación
                    </h2>


                    <Description 
                    text={
                        `
                        ¿Ya no te gusta la iluminación de tu hogar, oficina o en tu local comercial o quieres algo más moderno e inteligente? Eso tambien entra en nuestra area de especialización, porque

                        realizamos Instalaciones de iluminación adaptadas a las características de lugar de la instalación y a las necesidades de sus usuarios.
                        Antes de iniciar los trabajos de iluminación se calcula por adelantado la Eficiencia energética de las instalaciones de iluminación y factores que los afectan para poder evitar gastos innecesarios como en la instalación y tambien durante la explotación posterior de la instalación.
                        `
                    }
                    />

                </div>
                <img className="home-image-size1 screen" src={ilumination} alt="homeimage1"/>

            </div>

            <div id="Mantenimiento" className="homeSection">
                <h2 className="home-section-title mobile"> 
                    Nos adaptamos a sus necesidades
                </h2>
                <img className="home-image-size1" src={electrician} alt="homeimage1"/>
                <div className="home-section-info">
                    <h2 className="home-section-title screen"> 
                        Nos adaptamos a sus necesidades
                    </h2>

                    <Description 
                    text={
                        `
                        Les ofrecemos una amplia gama de servicios de mantenimiento electrico de baja tención. Problemas en los circuitos electricos, enchufes, sistemas de iluminación etc., los solucionamos de forma rápida y eféctiva.
                        En los servicios de mantenimiento, siempre intentamos adaptarnos a los horarios de nuestros clientes, para minimizar posibles causos inconveniencias para ellos, en el lugar de realización de los trabajos.
                        `
                    }
                    />
                </div>
            </div>


            <div  id="Camaras" className="homeSection">
                <div className="home-section-info">
                    <h2 className="home-section-title mobile"> 
                        Instalación Cámaras De Seguridad
                    </h2>
                    <img className="home-image-size1 mobile" src={security_camera} alt="homeimage1"/>

                    <h2 className="home-section-title screen"> 
                        Instalación Cámaras De Seguridad
                    </h2>

                    <Description 
                    text={
                        `
                        Ofrecemos un servicio completo de instalación de cámaras de seguridad, desde la recomendación del equipo adecuado hasta su conexión a la red. Nuestro equipo de expertos le ayudará a encontrar las mejores soluciones para su hogar o negocio, y le proporcionará herramientas para monitorear de manera efectiva sus espacios. Además, ofrecemos soporte técnico y mantenimiento para garantizar que esté completamente satisfecho con su sistema. Proteja sus bienes hoy mismo con nuestro servicio de instalación de cámaras de seguridad.
                        `
                    }
                    />

                </div>
                <img className="home-image-size1 screen" src={security_camera} alt="homeimage1"/>

            </div>

        </div>
    )
}


const Description=(props)=>{

    const [positon, setPosition]=useState("show-les");
    const [btn,setBtn]=useState("˅")

    const showMore=()=>{
        if(positon==="show-les"){
            setPosition("show-more")
            setBtn("˄")
            return
        }
        setPosition("show-les")
        setBtn("˅")

    }
    const text =props.text
    return(
        <div className="mobile-dropdown"> 
            <p className={`home-section-description ${positon}`}>
            {text}
            </p>
            <button onClick={showMore} className={`more-btn more-btn-${positon}`}>
                {btn}
            </button>
        </div>
    )
}

export default HomeContent;